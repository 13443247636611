import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Span, Em, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				InkCraft Tattoo Studio
			</title>
			<meta name={"description"} content={"Розкрийте свій внутрішній артистизм"} />
			<meta property={"og:title"} content={"InkCraft Tattoo Studio"} />
			<meta property={"og:description"} content={"Розкрийте свій внутрішній артистизм"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				InkCraft Tattoo Studio
			</Override>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11:37:06.785Z"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline2"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Про InkCraft Tattoo Studio
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					InkCraft Tattoo Studio є найкращим місцем для ентузіастів боді-арту. Наша студія створена як гостинний простір, де сходяться творчість і професіоналізм. Кожен із наших митців привносить унікальний стиль і перспективу, що дозволяє нам пропонувати різноманітний вибір дизайнів татуювань. Ми надаємо пріоритет гігієні та безпеці, дотримуючись найвищих стандартів, щоб забезпечити ваше благополуччя. Наша місія — створювати татуювання, які не тільки виглядають неймовірно, але й мають глибоке особисте значення для кожного клієнта.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Перетворюємо ваше тіла на полотно унікального персоналізованого мистецтва
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline4"
				>
					<Span font-weight="normal">
						Наша талановита команда художників прагне створити винятковий досвід татуювань, гарантуючи, що кожен дизайн відображає вашу індивідуальність та історію. Незалежно від того, чи ви новачок, чи досвідчений ентузіаст чорнила, ми пропонуємо комфортне та професійне середовище для всіх ваших потреб у татуюванні.
					</Span>
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Чому варто вибрати InkCraft Tattoo Studio?
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Ми цінуємо ваш час. Наші послуги виконуються швидко, без шкоди для якості, що гарантує, що ви повернетеся до свого дня стильно.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11:37:06.795Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-4.jpg?v=2024-05-24T11%3A37%3A06.795Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="24px 0 60px 0"
			md-padding="30px 0 30px 0"
			align-items="center"
			align-content="center"
			justify-content="center"
		>
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="29%"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				md-display="flex"
			>
				<Image
					width="450px"
					height="450px"
					src="https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11:37:06.777Z"
					lg-width="320px"
					lg-height="320px"
					mix-blend-mode="normal"
					srcSet="https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/2-3.jpg?v=2024-05-24T11%3A37%3A06.777Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="70%"
				display="flex"
				justify-content="center"
				md-width="100%"
				mix-blend-mode="lighten"
			>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text font="--lead" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green">
							Митці-експерти
						</Text>
					</Box>
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Наша команда складається з висококваліфікованих і досвідчених майстрів татуювань, які спеціалізуються на різних стилях, від традиційних і реалістичних до абстрактних і геометричних малюнків.
						</Text>
					</Box>
				</Box>
				<Box align-items="center" margin="0px 0px 16px 0px" display="flex">
					<Box display="flex" align-items="center" width="40%">
						<Text color="--green" font="--lead" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px">
							Індивідуальний дизайн
						</Text>
					</Box>
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Ми тісно співпрацюємо з нашими клієнтами, щоб створювати індивідуальні татуювання відповідно до їхнього бачення та вподобань, роблячи кожен виріб справді єдиним у своєму роді.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text margin="0px 0px 0px 0px" color="--green" font="--lead" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Високоякісні матеріали
						</Text>
					</Box>
					<Box width="60%" display="flex" align-items="center">
						<Text font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--light">
							Ми використовуємо лише найкращі чорнило та обладнання, що забезпечує яскраві кольори, чіткі деталі та довготривалі результати.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green" font="--lead">
							Гігієна та безпека
						</Text>
					</Box>
					<Box align-items="center" width="60%" display="flex">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Наша студія дотримується суворих протоколів стерилізації та використовує одноразові інструменти для підтримки чистого та безпечного середовища для кожного клієнта.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="40%">
						<Text lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="0px 0px 0px 0px" color="--green" font="--lead">
							Комфортна атмосфера
						</Text>
					</Box>
					<Box align-items="center" width="60%" display="flex">
						<Text margin="0px 0px 0px 0px" color="--light" font="--base" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							Ми прагнемо зробити нашу студію затишним і затишним місцем, де ви можете відчувати себе невимушено під час татуювання.
						</Text>
					</Box>
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
				<Box display="flex" align-items="flex-start" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Наша історія
			</Text>
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="80%"
			>
				<Text
					color="--light"
					font="--lead"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					text-align="left"
				>
					InkCraft Tattoo Studio народилася з пристрасті до мистецтва та бажання створити простір, де люди могли б виразити себе за допомогою унікальних та значущих татуювань.{" "}
					<br />
					<br />
					Наша подорож почалася з невеликої групи відданих майстрів, які поділяли спільне бачення: піднести татуювання до мистецтва та надати клієнтам винятковий персоналізований досвід. За ці роки ми перетворилися на процвітаючу студію, відому своєю креативністю, професіоналізмом і прагненням досконалості.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11:37:06.785Z"
					max-height="400px"
					width="100%"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/3-4.jpg?v=2024-05-24T11%3A37%3A06.785Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Професійне середовище
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наша студія – це чисте, затишне приміщення, оснащене найновішими технологіями та інструментами, що забезпечує безпечний і комфортний досвід для всіх клієнтів.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11:37:06.772Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665047916bbd2000236ad97d/images/1-3.jpg?v=2024-05-24T11%3A37%3A06.772Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Досвідчена команда
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					З багаторічним досвідом роботи в галузі наші художники мають знання та навички, щоб виконувати навіть найскладніші проекти з точністю та майстерністю.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="30%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="#ffffff"
					font="--headline2"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-transform="uppercase"
				>
					Наші послуги
				</Text>
				<Text margin="0px 0px 1rem 0px" color="--lightD2" font="--base">
					Ознайомтеся зі спектром послуг, які ми надаємо, і дізнайтеся, як ми можемо допомогти вам виразити свою індивідуальність через мистецтво.
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--base">
					<Em>
						Щоб дізнатися більше про наші послуги або записатися на консультацію, будь ласка, зв'яжіться з нами або відвідайте нашу студію.
					</Em>
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="68%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Індивідуальний дизайн татуювань
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font-weight="normal"
							>
								Ми спеціалізуємося на створенні індивідуальних дизайнів татуювань, які відображають унікальну історію та стиль кожного клієнта.
							</Em>
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Консультації: наші художники знайдуть час, щоб зрозуміти ваші ідеї та вподобання, гарантуючи, що остаточний дизайн буде адаптований до вашого бачення.{"\n"}
							<br />
							<br />
							Розробка ескізу: ми створюємо детальні ескізи та працюємо з вами, щоб удосконалити дизайн, поки він не стане ідеальним.
							<br />
							<br />
							{"\n"}Остаточне схвалення: Ви маєте останнє слово в процесі дизайну, забезпечуючи повне задоволення перед початком татуювання.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Прикриття татуювань
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Em font-weight="normal">
								Перетворення старих або непотрібних татуювань на красиві нові витвори мистецтва є однією з наших спеціальностей.
							</Em>
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Оцінка: ми оцінюємо ваше наявне татуювання та обговорюємо потенційні варіанти прикриття, які відповідають вашим уподобанням.{"\n"}
							<br />
							<br />
							Створення дизайну: наші майстри створюють дизайн, який ефективно покриває старе татуювання, одночасно впроваджуючи ваші нові ідеї.{"\n"}
							<br />
							<br />
							Безшовне виконання: ми гарантуємо, що нове татуювання ідеально поєднується з вашою шкірою та навколишніми татуюваннями.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Чорно-сірі татуювання
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Span font-weight="normal">
								<Em>
									Для тих, хто цінує позачасову елегантність чорно-сірих татуювань, ми пропонуємо послуги експертів у цьому класичному стилі.{" "}
								</Em>
							</Span>
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Техніка затінення: наші майстри використовують передові методи затінення, щоб створити глибину та розмірність вашого татуювання.{"\n"}
							<br />
							<br />
							Детальна робота: ми зосереджуємось на складних деталях, щоб ваше татуювання виглядало реалістично та вражаюче.
							<br />
							<br />
							{"\n"}Довговічність: наші високоякісні чорнила та точні технології гарантують, що ваше татуювання з часом гарно старіє.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Реалістичні татуювання
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Span font-weight="normal">
								<Em>
									Для тих, хто хоче реалістичні татуювання, наші послуги з реалістичності забезпечують фотореалістичні результати.
								</Em>
							</Span>
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Фотографії: ми використовуємо високоякісні фотографії, щоб зафіксувати кожну деталь об’єкта.{"\n"}
							<br />
							<br />
							Робота з тонкими лініями: наші майстри чудово працюють з тонкими лініями, забезпечуючи реалістичність і точність татуювання.
							<br />
							<br />
							{"\n"}Удосконалені методи: використовуючи передові методи, ми досягаємо приголомшливо реалістичних ефектів у вашому татуюванні.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Догляд за татуюванням
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Span font-weight="normal">
								<Em>
									Належний подальший догляд має важливе значення для довговічності та зовнішнього вигляду вашого татуювання. Ми надаємо комплексні послуги з подальшого догляду, щоб гарантувати ідеальне загоєння ваших нових чорнил.
								</Em>
							</Span>
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Початкові інструкції з догляду: наші майстри надають детальні інструкції щодо догляду за вашим татуюванням одразу після сеансу.{"\n"}
							<br />
							<br />
							Догляд за пацієнтом: ми пропонуємо консультацію для перевірки прогресу загоєння та вирішення будь-яких проблем.{"\n"}
							<br />
							<br />
							Продукція: ми рекомендуємо та постачаємо високоякісні засоби післяопераційного догляду для сприяння оптимальному загоєнню.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Кольорові татуювання
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Span font-weight="normal">
								<Em>
									Додайте яскравих кольорів своєму бодіарту за допомогою наших приголомшливих послуг кольорового татуювання.
								</Em>
							</Span>
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Вибір кольору: ми допоможемо вам вибрати ідеальні кольори, щоб покращити ваш дизайн і доповнити тон вашої шкіри.
							<br />
							<br />
							{"\n"}Змішування та градація: наші художники володіють навичками змішування та градації кольорів, створюючи плавні переходи та яскраві ефекти.{"\n"}
							<br />
							<br />
							Поради щодо догляду: ми надаємо вказівки щодо догляду за кольоровим татуюванням, щоб зберегти його яскравість на довгі роки.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});